import React from "react"

import Berlin from "./berlin";


const IndexPage = () => {
  return (
    <Berlin />)
}

export default IndexPage
